@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500;600;700&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif,'Roboto Condensed';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.listItem:hover {
  background: #e2e8f0;
  cursor: pointer;
}

@keyframes octocat-wave {
  0%, 100% {
    transform:rotate(0);
  }
  20%, 60% {
    transform:rotate(-20deg);

  } 40%, 80% {
      transform:rotate(10deg);
    }
}

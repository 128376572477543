.App {
  text-align: center;
  margin: 0;
  background-color: #61dafb;
}

.bg-theme {
  background-color: #61dafb !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 95vh;
}

.Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: white;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}

.container {
  max-width: unset !important;
}
.front {
  z-index: 100;
}
.slanted {
  z-index: -1;
  transform: skewY(3deg);
  margin-top: -5%;
}
.slanted-contents {
  transform: skewY(-3deg);
  padding-top: 5em;
}

.slanted-x {
  transform: skewx(14deg);
}
.slanted-x-contents {
  transform: skewx(-14deg) skewY(3deg) !important;
}

.left {
  /*overflow: hidden;*/
  margin-left: -5% !important;
}

.bigly {
  width: 120%;
}

.pointer {
  cursor: pointer;
}
.zoom {
  padding: 50px;
  transition: transform .2s; /* Animation */
  width: 200px;
  height: 200px;
  margin: 0 auto;
}

.zoom:hover {
  transform: scale(1.5); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.big-button-h {
  height: 100%;
}

.big-button-container {
  height: 20em !important;
}

.button-l {
  font-size: 1.5rem !important;
}

.slanted-r {
  z-index: -1;
  transform: skewY(-3deg);
  margin-top: -5%;
}
.slanted-contents-r {
  transform: skewY(3deg);
}

.cent {
  display: flex;
  width: 100%;
  max-width: 60%;
  margin: 0 auto;
  justify-content: center;
}

.slanted-banner {
  margin-top: -7em;
  height: 25em !important;
}

.navbar.navbar-light.transparent {
  background: transparent !important;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}

.accordion-item, .accordion-button {
  border-radius: 0 !important;
  box-shadow: none !important;
}

.form-check-input {
  clear: left;
}

.form-switch.form-switch-sm {
  margin-bottom: 0.5rem; /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-sm .form-check-input {
  height: 1rem;
  width: calc(1rem + 0.75rem);
  border-radius: 2rem;
}

.form-switch.form-switch-md {
  margin-bottom: 1rem; /* JUST FOR STYLING PURPOSE */
  outline: black 2rem;
}

.form-switch.form-switch-sm {
  margin-bottom: 1rem; /* JUST FOR STYLING PURPOSE */
  outline: black 2rem;
}

.form-switch.form-switch-sm.nm {
  margin-bottom: 0 !important; /* JUST FOR STYLING PURPOSE */
  max-width: 40px !important;
}

.form-switch.form-switch-md .form-check-input {
  height: 1.5rem;
  width: calc(2rem + 0.75rem);
  border-radius: 3rem;
  outline: black 2rem;
  box-shadow: 0 0 0 0.15rem black;
}

.form-switch.form-switch-lg {
  margin-bottom: 1.5rem; /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-lg .form-check-input {
  height: 2rem;
  width: calc(3rem + 0.75rem);
  border-radius: 4rem;
}

.form-switch.form-switch-xl {
  margin-bottom: 2rem; /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-xl .form-check-input {
  height: 2.5rem;
  width: calc(4rem + 0.75rem);
  border-radius: 5rem;
}
.dropdown-toggle::after {
  display: none !important;
}

/*.comment-list-group {*/
/*  overflow:scroll !important;*/
/*  height: calc(100vh - 56px) !important; !* Adjust the height, assuming the ActionBar is 56px tall *!*/
/*  !*overflow-y: auto !important;*!*/
/*  -webkit-overflow-scrolling: touch;*/
/*  padding-left: 8px !important;*/
/*  padding-right:8px !important;*/
/*}*/

.list-group{
  /*max-height: 500px;*/
  padding-bottom: 56px;
  overflow:scroll;
  height: calc(100vh - 56px) !important; /* Adjust the height, assuming the ActionBar is 56px tall */
  overflow-y: auto !important;
  -webkit-overflow-scrolling: touch;
  padding-left: 8px !important;
  padding-right:8px !important;
}

.inbox {
  height: 80vh;
}

.shadowed-card {
  box-shadow: inset 0px 0px 5px 3px rgb(20, 20, 20);

}

.green-background {
  background: #4ade80 !important;
}

.small-row {
  max-height: 4em;
}

.modal-height {
  height: 90% !important;
}
.timeline {
  border-left: 1px solid hsl(0, 0%, 90%);
  position: relative;
  list-style: none;
}

.timeline .timeline-item {
  position: relative;
}

.timeline .timeline-item:after {
  position: absolute;
  display: block;
  top: 0;
}

.timeline .timeline-item:after {
  background-color: hsl(0, 0%, 90%);
  left: -38px;
  border-radius: 50%;
  height: 11px;
  width: 11px;
  content: "";
}

.timeline-with-icons {
  border-left: 1px solid hsl(0, 0%, 90%);
  position: relative;
  list-style: none;
}

.timeline-with-icons .timeline-item {
  position: relative;
}

.timeline-with-icons .timeline-item:after {
  position: absolute;
  display: block;
  top: 0;
}

.timeline-with-icons .timeline-icon {
  position: absolute;
  left: -48px;
  background-color: hsl(217, 88.2%, 90%);
  color: hsl(217, 88.8%, 35.1%);
  border-radius: 50%;
  height: 31px;
  width: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline-with-icons .timeline-icon-completed {
  position: absolute;
  left: -48px;
  background-color: forestgreen;
  color: hsl(217, 88.8%, 35.1%);
  border-radius: 50%;
  height: 31px;
  width: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.tile-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  background-color: darkslateblue;
}

.tile:hover .tile-overlay {
  opacity: 100%;
}

/*.nav-bg {*/
/*  background-color: #a4f5fb;*/
/*}*/
/*.navbar-nav .dropdown-menu {*/
/*  right: 0 !important; !* Forces the dropdown to align to the right *!*/
/*  left: auto !important;*/
/*}*/

.cover {
  max-height: 30em;
  max-width: 30em;
}
.cover-m {
  max-height: 50em;
  max-width: 50em;
}

.item {
  max-height: 100em !important;
  height:100%;
  object-fit: cover;
  display: flex;
  width: 100%;
  max-width: 60%;
  margin: 0 auto;
  justify-content: center;
}

.loading-modal {
  height: 100px;
}

.min-font {
  font-size: 12px !important;
}

/* custom.css */
.hover-overlay {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
}

.hover-overlay img {
  display: block;
  width: 100%;
  height: auto;
}

.hover-overlay .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
  border-radius: 5px;
}

.hover-overlay:hover .overlay {
  opacity: 1;
}

.small-image {
  max-height: 15em;
}

.cent-s {
  display: flex;
  margin: 0 auto;
  justify-content: center;
}

/* custom.css */
.hover-shad {
  transition: box-shadow 0.3s;
  height: 200em;
}

.hover-shad:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 255, 0, 0.5);
}

.floating-button {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 100;
}
.floating-button-left {
  position: fixed;
  bottom: 20px;
  left: 90%;
  transform: translateX(-90%);
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 100;
}

.floating-button-img {
  width: 100px;
  height: 100px;
  z-index: 102;

}

.central-button-container {
  position: relative;
  width: 70px;
  height: 70px;
  bottom: 30px;
  z-index: 100;
}

.central-button {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  width: 70px;
  height: 70px;
  background-color: #1976d2;
  color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.central-button:hover {
  background-color: #115293;
}

.central-button-img {
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
}

.ul {
  list-style-type: none !important;
}

.activity-comments {
  min-height: 50vh;
  max-height: 80vh;
}

.list-switch {
  height: 40px !important;
  font-size: .5rem !important;
}

/* Add this CSS to your stylesheet */
.scaling-input {
  width: 100%;
  padding: 0.5em;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none; /* Prevent manual resizing */
  overflow: hidden; /* Hide overflow to ensure smooth auto-resizing */
}

.scaling-input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}

.scrollable-container {
  max-height: 100vh; /* Or another appropriate max height */
  overflow-y: auto; /* Enable vertical scrolling */
  -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
}

.adjusted-container {
  height: calc(100vh - 56px) !important; /* Adjust the height, assuming the ActionBar is 56px tall */
  overflow-y: auto;
  padding-bottom: 56px !important; /* Ensure no content is hidden under the ActionBar */
}
.custom-modal {
  max-height: 75vh !important;
  display: flex;
  flex-direction: column;
}

.custom-modal-content {
  overflow-y: auto;
}
.custom-modal-dialog {
  margin: 0 auto;
}

.scrollable-ul {
  max-height: 200px; /* Adjust this value as needed */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

}
.scrollable-body {
  max-height: 90vh; /* Adjust this value as needed */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

}

.lower-modal {
  z-index: 1000 !important;
}

.modal-backdrop {
  z-index: 103 !important;
}

.home-image-web {
  max-height: 50vh !important;
}

.home-container-blue {
  background-color: #61dafb;
  height: 70em;
}
.home-container-blue-mobile {
  background-color: #61dafb;
  height: 60em;
}

.theme-blue {
  background-color: #61dafb !important;
}


.home-container-tan {
  background-color: #F9F4E8;
  height: 40em;
}

.home-container-tan-mobile {
  background-color: #F9F4E8;
  height: auto;
}

.home-book-images {
  max-height: 18em!important;
  width: auto;
}
.home-cat {
  max-height: 12em!important;
  width: auto;
}

.home-cats {
  max-height: 30em!important;
  width: auto;

}
.home-cats-mobile {
  max-height: 20em!important;
  width: auto;
  transform: scaleX(-1);
}

.left-pg {
  text-align: right;
}

.home-phone {
  max-height: 30em !important;
  width: auto;
}

.row-gutterless {
  --mdb-gutter-x: 0rem !important;
}

.glow-card {
  /*box-shadow: 0 0 5px 3px rgba(0, 255, 255, 0.5) !important;*/
  border: 5px solid #61dafb !important;
}
/*.container {*/
/*  --mdb-gutter-x: 0rem !important;*/
/*}*/